import Center from '@/components/center';
import EmptyData from '@/components/EmptyData';
import useLang from '@/hooks/useLang';
import { ILog } from '@/types/user';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';

const LogCard = ({ data }: { data: ILog }) => {
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        borderRadius: 1,
      }}
    >
      <Box p={{ xs: 2, md: 3 }}>
        <Typography variant="body1" fontWeight={500}>
          <span style={{ color: '#36A8F4', fontWeight: 500 }}>
            R$ {(+data?.money || 0).toFixed(2)}
          </span>{' '}
          {data.infos}
        </Typography>
      </Box>
      <Divider></Divider>
      <Box p={{ xs: 2, md: 3 }}>
        <Typography variant="body1" color="text.secondary">
          {dayjs(+(data?.add_time || 0) * 1000).format('YYYY-MM-DD HH:mm:ss')}
        </Typography>
      </Box>
    </Box>
  );
};

interface Props {
  total: number;
  loading: boolean;
  logs: ILog[];
}

const LogsList: React.FC<Props> = (props: Props) => {
  // const isMobile = useResponsive('down', 'md');
  // return isMobile ? <List {...props} /> : <LogsTable {...props} />;
  return <List {...props} />;
};

const LogsTable = ({ loading, total, logs }: Props) => {
  const { __ } = useLang();
  if (loading) {
    return (
      <Center py={4}>
        <CircularProgress />
      </Center>
    );
  }

  return (
    <TableContainer
      sx={{
        borderRadius: '8px',
        overflowX: 'hidden',
        border: '1px solid #272142',
      }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                color: '#8B899C',
                bgcolor: 'background.paper',
                borderColor: '#272142',
                width: '25%',
                minWidth: '25%',
                px: 1,
                maxWidth: '25%',
              }}
            >
              {__('Log')}
            </TableCell>
            <TableCell
              sx={{
                color: '#8B899C',
                bgcolor: 'background.paper',
                borderColor: '#272142',
                width: '25%',
                minWidth: '25%',
                px: 1,
                maxWidth: '25%',
              }}
            >
              {__('Time')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((item, index) => {
            return (
              <TableRow
                key={`${item.id}-${index}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  sx={{
                    borderColor: '#272142',
                    width: '25%',
                    minWidth: '25%',
                    px: 1,
                    maxWidth: '25%',
                  }}
                >
                  <Typography
                    variant="body1"
                    color="text.disabled"
                    fontWeight={700}
                  >
                    <span style={{ color: '#ffffff', fontWeight: 700 }}>
                      R$ {(+item?.money || 0).toFixed(2)}
                    </span>{' '}
                    {item.infos}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    borderColor: '#272142',
                    width: '25%',
                    minWidth: '25%',
                    px: 1,
                    maxWidth: '25%',
                  }}
                >
                  {dayjs(+(item?.add_time || 0) * 1000).format(
                    'YYYY-MM-DD HH:mm:ss',
                  )}
                </TableCell>
              </TableRow>
            );
          })}
          {logs.length === 0 && (
            <TableRow>
              <TableCell colSpan={2}>
                <Center py={10}>
                  <EmptyData></EmptyData>
                </Center>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const List = ({ loading, total, logs }: Props) => {
  if (loading) {
    return (
      <Center py={4}>
        <CircularProgress />
      </Center>
    );
  }

  if (total === 0) {
    return (
      <Center py={10}>
        <EmptyData></EmptyData>
      </Center>
    );
  }

  return (
    <Box>
      <Stack height="48px" direction="row" alignItems="center">
        <Typography variant="subtitle2" fontWeight={700}>
          Log Records
        </Typography>
      </Stack>
      <Grid container columns={24} spacing={{ xs: 2, md: 3 }}>
        {logs.map((item) => {
          return (
            <Grid item xs={24} sm={12} md={8} key={item.id}>
              <LogCard data={item} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default LogsList;
