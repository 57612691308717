import IMAGE_COIN from '@/assets/icons/coin.png';
import IC_QUESTION from '@/assets/icons/icon_question.png';
import ICON_SIGNOUT from '@/assets/icons/icon_signout.png';
import ICON_REFRESH from '@/assets/icons/ic_refresh.svg';
import DEFAULT_AVATAR from '@/assets/images/avatar-default.png';
import Center from '@/components/center';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import services from '@/services';
import {
  isOpenDepositState,
  isOpenVipRebateState,
  meDrawerState,
  vipListState,
} from '@/stores/auth';
import { homeTab } from '@/stores/config';
import { ColorfulBg } from '@/styles';
import { HomeTab } from '@/types';
import { IVip } from '@/types/user';
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DailySignView } from './components/DailySignView';
import MeMenus from './components/Menus';
import MenusWeb from './components/MenusWeb';
import { TGGuide } from './components/TGGuide';
import VipRebateDrawer from './components/VipRebateDrawer';
import WithdrawButton from './Withdraw';

interface Props {
  noHeader?: boolean;
}

const MePage: React.FC<Props> = (props: Props) => {
  const { user, wallet, getWallet } = useAuth();
  const navigate = useNavigate();
  const { __ } = useLang();
  const isMobile = useResponsive('down', 'md');
  const [open, setOpen] = useBoolean();
  const { logout } = useAuth();
  const theme = useTheme();
  const [vip, setVip] = useState<IVip | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [wloading, setWLoading] = useState<boolean>(false);
  const setOpenVipRebate = useSetRecoilState(isOpenVipRebateState);
  const setVipList = useSetRecoilState(vipListState);
  const openDiposit = useSetRecoilState(isOpenDepositState);
  const setOpenMeDrawer = useSetRecoilState(meDrawerState);
  const homeTabValue = useRecoilValue(homeTab);

  const handleRefreshWallet = async () => {
    setWLoading(true);
    await getWallet();
    setWLoading(false);
  };
  const handleClickDeposit = () => {
    setOpenMeDrawer(false);
    openDiposit(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await services.config.getVipList();
      if (Array.isArray(data)) {
        const current = data.find((i) => +i.is_user_vip === 1);
        setVip(current || null);
        setVipList(data || []);
      }
      setLoading(false);
    };

    if (homeTabValue === HomeTab.Me) {
      fetchData();
    }
  }, [homeTabValue]);

  const handleSignout = () => {
    logout();
    setOpen.off();
    SnackbarUtils.success(__('Sign out successfully'));
  };

  const _vipRebateRule = () => {
    setOpenVipRebate(true);
  };

  const closePage = () => {
    setOpenMeDrawer(false);
  };

  if (loading || !user) {
    return (
      <Center py={10}>
        <CircularProgress />
      </Center>
    );
  }

  return (
    <Box minHeight="100vh">
      <Box py={{ xs: 1.5, md: 2 }}>
        <Stack direction="row" spacing={3}>
          <MenusWeb active="home" />
          <Box flex="auto">
            {/* 账号信息 */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1.5}
              >
                <Box
                  sx={{
                    width: '55px',
                    height: '55px',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={user?.avatar || DEFAULT_AVATAR}
                    alt=""
                    style={{ width: 55, height: 55, objectFit: 'cover' }}
                  />
                </Box>
                <Box>
                  <Stack flexDirection={'row'} alignItems="center">
                    <Typography
                      onClick={() => (!isMobile ? navigate('/me') : null)}
                      variant="h2"
                      fontWeight={700}
                    >
                      {user?.name || ''}
                    </Typography>
                    <img
                      src={user?.level_pic}
                      alt=""
                      style={{ width: 'auto', height: 16, marginLeft: '4px' }}
                    />
                  </Stack>

                  <Typography
                    mt={'8px'}
                    variant="subtitle2"
                    color={alpha(theme.palette.info.main, 0.56)}
                  >
                    {__('ID: ')}
                    {user.uid}
                  </Typography>
                </Box>
              </Stack>
              {/* <Stack direction="row" alignItems="center" spacing={1.5}>
                <SocialButton type="facebook" />
                <SocialButton type="google" />
              </Stack> */}
            </Stack>
            {/* 七天签到 */}
            {/* <DailySignBox /> */}
            <DailySignView />
            {/* 钱包 */}
            <Box
              sx={{
                bgcolor: isMobile ? 'background.paper' : 'background.neutral',
                borderRadius: '10px',
                p: { xs: '12px 16px', md: 3 },
                mt: 2.5,
              }}
            >
              <Stack
                sx={
                  isMobile
                    ? {}
                    : {
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }
                }
              >
                <Stack>
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      color={alpha(theme.palette.info.main, 0.56)}
                    >
                      {__('My Wallet')}
                    </Typography>
                    {wloading ? (
                      <CircularProgress />
                    ) : (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <img
                          src={IMAGE_COIN}
                          alt=""
                          style={{ width: 24, height: 24 }}
                        />
                        <Typography
                          mr={0.5}
                          variant="h1"
                          fontSize={{ md: '40px' }}
                          fontWeight={700}
                        >
                          {(+(wallet?.money || 0)).toFixed(2)}
                        </Typography>
                        <img
                          src={ICON_REFRESH}
                          alt=""
                          style={{ width: 24, height: 24 }}
                          onClick={handleRefreshWallet}
                        />
                      </Stack>
                    )}
                  </Stack>
                  <Stack flexDirection={'row'} mt="16px">
                    <Typography
                      variant="body2"
                      color={alpha(theme.palette.info.main, 0.56)}
                    >
                      <span>{__('wallettip1')}</span>
                      <span style={{ color: 'white' }}>{` R$${
                        wallet?.no_dama_money || 0
                      } `}</span>
                      <span>{__('wallettip2')}</span>
                    </Typography>
                    <img
                      src={IC_QUESTION}
                      alt=""
                      style={{ width: 16, height: 16, marginLeft: '4px' }}
                      onClick={_vipRebateRule}
                    />
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: '16px',
                  }}
                >
                  <WithdrawButton type={1} />
                  <Button
                    onClick={handleClickDeposit}
                    fullWidth
                    sx={[
                      {
                        background: ColorfulBg,
                        height: '40px',
                        borderRadius: '8px',
                      },
                      !isMobile
                        ? { ml: '12px', width: '147px' }
                        : { width: 'auto', flex: 1, ml: '16px' },
                    ]}
                    variant="contained"
                  >
                    {__('充值')}
                  </Button>
                </Stack>
              </Stack>
            </Box>
            {/* TG引导 */}
            {isMobile && <TGGuide />}
            {/* 菜单 */}
            {isMobile && <MeMenus />}
            {/* 退出登录 */}
            {isMobile && (
              <Box
                sx={{
                  border: { xs: '1px solid #323D4D', md: 'none' },
                  bgcolor: { md: 'background.neutral' },
                  borderRadius: '10px',
                  p: { xs: '12px 16px', md: 3 },
                  mt: 2.5,
                }}
                onClick={setOpen.on}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={'center'}
                  spacing={2}
                >
                  <img
                    src={ICON_SIGNOUT}
                    alt=""
                    style={{ width: 20, height: 20 }}
                  />
                  <Typography
                    variant="subtitle2"
                    color={alpha(theme.palette.info.main, 0.56)}
                  >
                    {__('Sign Out')}
                  </Typography>
                </Stack>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
      <VipRebateDrawer />
      {/* 退出确定弹窗 */}
      <Dialog onClose={setOpen.off} open={open}>
        {/* <CloseButton onClick={setOpen.off} /> */}
        <Box
          py={3}
          px={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="subtitle1" fontWeight={800}>
            {__('是否确定退出？')}
          </Typography>
        </Box>

        <Box px={2} pb={2}>
          <Stack width="100%" direction="row" alignItems="center" spacing={1.5}>
            <Button
              fullWidth
              variant="outlined"
              size="small"
              onClick={setOpen.off}
              sx={{
                height: '48px',
                width: '132px',
                color: '#ffffff',
                borderColor: '#323D4D',
              }}
            >
              {__('Cancel')}
            </Button>
            <Button
              fullWidth
              sx={{ background: ColorfulBg, height: '48px', width: '132px' }}
              variant="contained"
              size="small"
              onClick={handleSignout}
            >
              {__('Sure')}
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </Box>
  );
};

export default MePage;
