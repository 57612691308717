import COMMISSION_ICON from '@/assets/icons/commission-icon.png';
import icon_tip from '@/assets/icons/icon_tip.png';
import Center from '@/components/center';
import DatePicker from '@/components/DatePicker/DatePicker';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import { useAgentSummary } from '@/hooks/useAgentSummary';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import services from '@/services';
import { IMember } from '@/types/user';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { AgentSummary } from './AgentSummary';
import MembersList from './MembersList';

interface Props {}

const TabMembers: React.FC<Props> = (props: Props) => {
  const [members, setMembers] = useState<IMember[]>([]);
  const [loading, setLoading] = useBoolean();
  const [total, setTotal] = useState<number>(0);
  const [commissionsMoney, setCommissionsMoney] = useState<number>(0);
  const { user } = useAuth();
  const [start, setStart] = useState<string>(
    dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
  );
  const [end, setEnd] = useState<string>(
    dayjs().add(1, 'day').format('YYYY-MM-DD'),
  );
  const { __ } = useLang();

  const { data: agentSummary, list: agentPeople } = useAgentSummary(start, end);

  useEffect(() => {
    if (!start || !end) {
      return;
    }
    const startDate = dayjs(start);
    const endDate = dayjs(end);

    if (startDate.isAfter(endDate)) {
      SnackbarUtils.error(__('结束时间不能小于开始时间'));
      return;
    }

    fetchMembers({
      page: 1,
      pagesize: 200,
      total: 1,
      start_time: `${start} 00:00:00`,
      end_time: `${end} 23:59:59`,
    });
  }, [start, end]);

  const fetchMembers = async (params: any) => {
    setLoading.on();
    const res = await services.auth.getMembers(params);
    setMembers(res?.list || []);
    setTotal(+res?.total?.all_num || 0);
    setCommissionsMoney(+res?.total?.commission || 0);
    setLoading.off();
  };

  if (!user) {
    return (
      <Center py={10}>
        <CircularProgress />
      </Center>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          px: 3,
          py: 1.5,
          borderRadius: 1,
          bgcolor: 'card.main',
          mb: 2,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Stack flex="auto" spacing={1.5}>
            <Typography variant="h2" fontWeight={700}>
              R${' '}
              <span style={{ fontSize: 32 }}>
                {agentSummary?.agentAmt.toFixed(2) || 0}
              </span>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {__('累计获得佣金')}
            </Typography>
          </Stack>
          <img alt="" src={COMMISSION_ICON} style={{ width: 64, height: 86 }} />
        </Stack>
      </Box>
      <Stack direction="row" alignItems="center" spacing={1} mb={'16px'}>
        <img alt="" src={icon_tip} style={{ width: 14, height: 14 }} />
        <Typography variant="body2" color={'text.secondary'}>
          {__('The following data is updated every 10 minutes')}
        </Typography>
      </Stack>
      <AgentSummary type={1} data={agentSummary} />
      <Box
        sx={{
          border: '1px solid #323D4D',
          borderRadius: { xs: '5px', md: '8px' },
          py: 0,
          px: 2.5,
          mb: 2,
          width: { xs: '100%', md: '280px' },
        }}
      >
        <Stack
          sx={{ height: '40px' }}
          direction="row"
          alignItems="center"
          // justifyContent={'space-between'}
          spacing={2}
        >
          <Stack
            textAlign="center"
            flex="auto"
            direction="row"
            alignItems="center"
            // sx={{ width: '190px' }}
          >
            <DatePicker
              date={start}
              onChange={(v) => setStart(v)}
              maxDate={dayjs(end)}
            />
            <Typography variant="body1">-</Typography>
            <DatePicker
              date={end}
              onChange={(v) => setEnd(v)}
              minDate={dayjs(start)}
            />
          </Stack>
          <CalendarMonthIcon sx={{ fontSize: 16 }} />
        </Stack>
      </Box>
      <AgentSummary type={2} data={agentSummary} />
      <MembersList loading={loading} members={agentPeople} total={total} />
    </Box>
  );
};

export default TabMembers;
