// material-ui
import useResponsive from '@/hooks/useResponsive';
import { homeTab } from '@/stores/config';
import { HomeTab } from '@/types';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import IMAGE_LOGO from '../../assets/topnav/icon_logo_xxdwin.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = (props: { onClick?: () => void }) => {
  const navigate = useNavigate();
  const setHomeTab = useSetRecoilState(homeTab);
  const isMobile = useResponsive('down', 'md');
  const location = useLocation();

  const handleGoHome = () => {
    if (isMobile && location.pathname === '/') {
      setHomeTab(HomeTab.Home);
    } else {
      navigate('/');
    }
  };
  return (
    <Box
      sx={{
        // width: { md: 160, xs: "auto" },
        display: 'flex',
        pr: 2,
        cursor: 'pointer',
      }}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        } else {
          handleGoHome();
        }
      }}
    >
      <img
        src={IMAGE_LOGO}
        alt="logo"
        style={{ maxWidth: 125, maxHeight: 32, width: '100%', height: 'auto' }}
      />
    </Box>
  );
};

export default LogoSection;
