import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Center from "../../components/center";
import SearchGameCard from "../../components/game-card";
import services from "../../services";
import { IGame } from "../../types";

interface Props {
  category: string;
}

const PAGE_SIZE = 30;
const CommonSearchPage: React.FC<Props> = (props: Props) => {
  const [list, setList] = useState<IGame[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const [slugs, setSlugs] = useState<string>("all");
  // const ref = useRef<HTMLDivElement | null>(null);
  // const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const fetchGameList = async () => {
      setLoading(true);
      try {
        const { data } = await services.home.getGameList({
          page,
          limit: PAGE_SIZE,
          base_game_category_slugs: props.category,
          game_category_slugs: slugs === "all" ? "" : slugs,
          order: "",
          search_keyword: "",
        });
        const newList = data?.records || [];
        const total = data?.meta?.total_records || 0;
        if (list.length < page * PAGE_SIZE) {
          setList([...list, ...newList]);
        } else {
          setList([...newList]);
        }
        setTotal(total);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchGameList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleLoadMore = async () => {
    if (total <= list.length) {
      console.log("没有更多了");
      return;
    }

    setPage(page + 1);
    console.log(11);
  };

  return (
    <Box>
      {/* <Stack direction="row" spacing={2}>
        <Select
          sx={{ flex: 1 }}
          value={slugs}
          onChange={(e) => setSlugs(e.target.value)}
        >
          <MenuItem value="all">All Slot</MenuItem>
          <MenuItem value="bonus-buy">Bonus Buy</MenuItem>
        </Select>
        <Select
          sx={{ flex: 1 }}
          value={slugs}
          onChange={(e) => setSlugs(e.target.value)}
        >
          <MenuItem value="all">All Slot</MenuItem>
          <MenuItem value="bonus-buy">Bonus Buy</MenuItem>
        </Select>
        <Select
          sx={{ flex: 1 }}
          value={slugs}
          onChange={(e) => setSlugs(e.target.value)}
        >
          <MenuItem value="all">All Slot</MenuItem>
          <MenuItem value="bonus-buy">Bonus Buy</MenuItem>
        </Select>
      </Stack> */}
      <InfiniteScroll
        style={{ height: "auto", overflow: "unset" }}
        dataLength={list.length}
        next={handleLoadMore}
        hasMore={total > 0 && total > list.length}
        loader={
          <Center sx={{ py: 4 }}>
            <CircularProgress />
          </Center>
        }
        endMessage={
          loading || list.length === 0 ? null : (
            <Center sx={{ py: 4 }}>
              <Typography variant="body2" sx={{ color: "#999999" }}>
                No more
              </Typography>
            </Center>
          )
        }
      >
        {list.length === 0 ? (
          <Center sx={{ py: 4 }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Typography variant="body1" sx={{ color: "#999999" }}>
                No Data
              </Typography>
            )}
          </Center>
        ) : null}
        <Grid container columns={24} spacing={2}>
          {list.map((item) => {
            return (
              <Grid item key={item.id} xs={8} sm={8} md={4}>
                <SearchGameCard data={item} />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
    </Box>
  );
};

export default CommonSearchPage;
