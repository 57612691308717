import IMAGE_COIN from '@/assets/icons/coin.png';
import Center from '@/components/center';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import WithdrawButton from '@/pages/Me/Withdraw';
import services from '@/services';
import {
  agentLevelState,
  isOpenTransferState,
  isOpenWithdrawerState,
} from '@/stores/auth';
import { homeTab } from '@/stores/config';
import { HomeTab } from '@/types';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useCopyToClipboard } from 'usehooks-ts';
import CommissionsRules from './CommissionsRules';
import ICON_FACEBOOK from './images/icon_facebook.png';
import ICON_WHATS from './images/icon_whats.png';
import MSG_IMAGE from './images/msg.png';
import SHARELINK from './images/shareLink.png';
import TransferDrawer from './TransferDrawer';
import {ColorfulBg} from "@/styles";

interface Props {}

const generateUrl = (id?: number) => {
  return `${window.location.origin}?code=${id || ''}`;
};

const TabCommissions: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  const { user, getUserInfo } = useAuth();
  const [_value, copy] = useCopyToClipboard();
  const setOpenWithdraw = useSetRecoilState(isOpenWithdrawerState);
  const setAgentLevelList = useSetRecoilState(agentLevelState);
  const setOpenTransfer = useSetRecoilState(isOpenTransferState);
  const [rentouMoney, setRentouMoney] = useState<string>('');
  const homeTabValue = useRecoilValue(homeTab);

  const isMobile = useResponsive('down', 'md');

  const agentUrl = generateUrl(user?.uid);

  const fetchData = async () => {
    let data = await services.config.getAgentLevelInfo({
      sid: '1',
    });
    let agentList = data?.data || [];

    let nowAgent = agentList.find(
      (a: { is_user_agent: number }) => a.is_user_agent == 1,
    );

    if (nowAgent) {
      setRentouMoney(`${nowAgent.rentou_money}`);
    }

    setAgentLevelList(data?.data || []);
  };

  useEffect(() => {
    getUserInfo();
    fetchData();
  }, []);

  useEffect(() => {
    if (homeTabValue === HomeTab.Invite) {
      getUserInfo();
      fetchData();
    }
  }, [homeTabValue]);

  const handleCopy = async (textu: string) => {
    console.log('text:', textu);
    const clipboard = navigator.clipboard || {
      writeText: (text) => {
        const input = document.createElement('input');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
      },
    };
    if (clipboard) {
      clipboard.writeText(textu);
      SnackbarUtils.success(__('Copy to clipboard'));
    }
    //   const success = await copy(textu);
    //   if (success) {
    //     SnackbarUtils.success(__('Copy to clipboard'));
    //   }
  };

  const handleClickTransfer = () => {
    setOpenTransfer(true);
  };

  if (!user) {
    return (
      <Center py={10}>
        <CircularProgress />
      </Center>
    );
  }

  const shareView = () => {
    let width = isMobile ? '36px' : '48px';
    return (
      <Stack
        sx={
          isMobile
            ? {
                flexDirection: 'row',
                mt: '8px',
                alignItems: 'center',
              }
            : {}
        }
      >
        <Typography
          textAlign="center"
          mb={{ md: 1 }}
          fontWeight={500}
          variant="body2"
          color="text.primary"
        >
          {__('Share to')}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={-1}
          sx={{ ml: '16px' }}
        >
          <IconButton
            sx={{
              width: width,
              height: width,
            }}
          >
            <img src={ICON_FACEBOOK} alt="" />
          </IconButton>
          <IconButton
            sx={{
              width: width,
              height: width,
            }}
          >
            <img src={ICON_WHATS} alt="" />
          </IconButton>
        </Stack>
      </Stack>
    );
  };

  return (
    <Box>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={1.5}>
        {/* 邀请地址 */}
        <Box
          sx={{
            // bgcolor: 'background.paper',
            backgroundImage: `url(${SHARELINK})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            borderRadius: 1,
            p: {
              xs: '25px 16px 24px',
              md: 3,
            },
            mt: { xs: 1.5, md: 0 },
            position: 'relative',
            flex: 1,
            height: isMobile ? '156px' : '174px',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '-20px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '40px',
              display: { xs: 'block', md: 'none' },
            }}
          >
            <img src={MSG_IMAGE} alt="" />
          </Box>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 0, md: 3 }}
            alignItems={{ xs: '', md: 'end' }}
          >
            <Stack
              flex={{ md: 'auto' }}
              spacing={{ xs: 1, md: 2 }}
              alignItems="start"
            >
              <Box
                display={{ xs: 'none', md: 'block' }}
                sx={{
                  width: '48px',
                  height: '48px',
                }}
              >
                <img src={MSG_IMAGE} alt="" />
              </Box>
              <Box
                sx={{
                  border: '1px solid #35314B',
                  borderRadius: '10px',
                  // pr: 0.5,
                  width: '100%',
                  bgcolor: 'common.white',
                }}
              >
                <Stack
                  height={36}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    px={1.5}
                    variant="body2"
                    color={'background.default'}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {agentUrl}
                  </Typography>
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={() => handleCopy(agentUrl)}
                    sx={{
                      bgcolor: 'primary.main',
                      borderRadius: '6px',
                      textTransform: 'none',
                    }}
                    style={{ backgroundColor: ColorfulBg }}
                  >
                    <ContentCopyRoundedIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                </Stack>
              </Box>
            </Stack>

            {!isMobile ? shareView() : null}
          </Stack>
          {isMobile ? shareView() : null}
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {__('invite Tip')}
            <span style={{ fontSize: 16, color: '#ffffff', fontWeight: 500 }}>
              {__('rentouMoney').replace('[money]', rentouMoney)}
            </span>
          </Typography>
        </Box>
        {/* 代理钱包 */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '10px',
            p: { xs: '12px 16px', md: 3 },
            mt: 2.5,
            flex: 1,
            height: isMobile ? '166px' : '174px',
          }}
        >
          <Stack>
            <Stack>
              <Stack spacing={1}>
                <Typography variant="body1" color={'text.secondary'}>
                  {__('Commissions')}
                </Typography>

                <Stack direction="row" alignItems="center" spacing={1}>
                  <img
                    src={IMAGE_COIN}
                    alt=""
                    style={{ width: 24, height: 24 }}
                  />
                  <Typography
                    mr={0.5}
                    variant="h1"
                    fontSize={{ md: '40px' }}
                    fontWeight={700}
                  >
                    {user?.agent_money?.toFixed(2) || 0}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: isMobile ? 'space-between' : 'flex-start',
                mt: '16px',
              }}
            >
              <WithdrawButton type={2} />
              <Button
                onClick={handleClickTransfer}
                fullWidth
                sx={[
                  {
                    bgcolor: ColorfulBg,
                    height: '40px',
                    borderRadius: '8px',
                  },
                  !isMobile
                    ? { ml: '12px', width: '147px' }
                    : { width: 'auto', flex: 1, ml: '16px' },
                ]}
                variant="contained"
              >
                {__('Transfer')}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>

      <CommissionsRules />
      <TransferDrawer />
    </Box>
  );
};

export default TabCommissions;
