import Center from '@/components/center';
import { VipIcons } from '@/pages/Vip/consts';
import { IVipItem } from '@/types/vip';
import {
  Box,
  CircularProgress,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material';

export const VipMenus = (props: {
  active: number;
  onChange: (level: number) => void;
  isLoading: boolean;
  data: IVipItem[];
}) => {
  if (props.isLoading) {
    return (
      <Box p={2} bgcolor="card.main" borderRadius={1}>
        <Center py={10}>
          <CircularProgress />
        </Center>
      </Box>
    );
  }

  return (
    <Box p={2} bgcolor="card.main" borderRadius={1}>
      <Stack spacing={0.5}>
        {props.data.map((item, index) => {
          const isActive = index === props.active;
          return (
            <ListItemButton
              key={index}
              sx={{
                p: 1.5,
                border: '1px solid',
                borderColor: isActive ? 'primary.main' : 'transparent',
                borderRadius: 1,
                cursor: 'pointer',
              }}
              onClick={() => {
                props.onChange(index);
              }}
            >
              <Stack spacing={1} direction="row" alignItems="center">
                <img src={VipIcons[index + 1]} alt="" width={24} height={24} />
                <Typography variant="body1" fontWeight={700}>
                  VIP {index + 1}
                </Typography>
              </Stack>
            </ListItemButton>
          );
        })}
      </Stack>
    </Box>
  );
};
