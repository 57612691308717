import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import services from "../../services";
import { IBoxInfo } from "../../types/box";
import Center from "../center";
import CouponCard from "./CouponCard";

interface Props {
  boxId?: number;
}

const SpinnerRewards: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [info, setInfo] = useState<IBoxInfo | null>(null);

  useEffect(() => {
    const fetchRewards = async (id: number) => {
      setLoading(true);
      try {
        const { data } = await services.config.getBoxInfoById(id);
        console.log(data);
        setInfo(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    props.boxId && fetchRewards(props.boxId);
  }, [props.boxId]);

  return (
    <Stack spacing="20px" sx={{ overflow: "hidden" }}>
      <Typography textAlign="center" variant="h2">
        Rewards
      </Typography>
      <Box maxHeight={{ md: "600px" }} sx={{ overflowY: { md: "scroll" } }}>
        <Stack spacing="20px">
          {loading ? (
            <Center py={3}>
              <CircularProgress />
            </Center>
          ) : info === null ? null : (
            <>
              {info.box_items.map((item) => {
                return <CouponCard data={item} key={item.box_id} />;
              })}
            </>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default SpinnerRewards;
