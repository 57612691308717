// export const testHost = 'http://192.168.2.11:7080';
// export const testHost = 'https://www.cassino27.com/api';//线上环境，租户1
// export const testHost = 'https://www.ccawin.com/api';//线上环境，租户2
// export const testHost = 'https://www.ggowin.com/api';//线上环境，租户3
// export const testHost = 'https://www.ccewin.com/api';//线上环境，租户4
// export const testHost = 'https://www.xxswin.com/api';//线上环境，租户5
// export const testHost = 'https://www.kkawin.com/api';//线上环境，租户6
// export const testHost = 'https://www.ggawin.com/api';//线上环境，租户7
// export const testHost = 'https://www.ggcwin.com/api';//第二套线上环境，租户1
// export const testHost = 'https://www.ccgwin.com/api';//第三套线上环境，租户1
export const testHost = 'https://www.xxdwin.com/api';//第四套线上环境，租户1
// export const testHost = 'https://www.br-baby.com/api';//测试环境
// export const testHost = 'http://192.168.2.131:7080';//测试环境