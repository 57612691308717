import IMAGE_COIN from '@/assets/icons/coin.png';
import ICON_REFRESH from '@/assets/icons/ic_refresh.png';
import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import WithdrawButton from '@/pages/Me/Withdraw';
import { ColorfulBg } from '@/styles';
import { alpha, Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';

interface Props {}

const WalletBox: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  const { wallet, getWallet } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();

  const handleRefresh = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    await getWallet();
    setIsLoading(false);
  };
  return (
    <Box
      sx={{
        bgcolor: 'background.neutral',
        px: 1.5,
        py: 1,
        borderRadius: '8px',
        minWidth: 168,
      }}
    >
      <Typography
        variant="body2"
        color={alpha(theme.palette.info.main, 0.56)}
        mb={1}
      >
        {__('My Wallet')}
      </Typography>
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ mb: '16px' }}
      >
        <img style={{ width: 24, height: 24 }} src={IMAGE_COIN} alt="coin" />
        <Box>
          <Typography variant="h2">
            {(+(wallet?.money || 0)).toFixed(2)}
          </Typography>
        </Box>
        <Box
          sx={{
            width: 24,
            height: 24,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={handleRefresh}
        >
          {isLoading ? (
            <CircularProgress size={24} color="info" />
          ) : (
            <img style={{ width: 24, height: 24 }} src={ICON_REFRESH} alt="" />
          )}
        </Box>
      </Stack>
      <WithdrawButton bgColorT={ColorfulBg} type={1} />
    </Box>
  );
};

export default WalletBox;
