import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

import SinnerLevel from "../../components/LuckyDrawer/SinnerLevel";
import Spinner from "../../components/LuckyDrawer/Spinner";
import SpinnerRewards from "../../components/LuckyDrawer/SpinnerRewards";
import Center from "../../components/center";
import useMyBox from "../../hooks/useMyBox";
import useMyXp from "../../hooks/useMyBox copy";
import { SpinnerTabType } from "../../types";

interface Props {}

const TabItem = (props: {
  tab: SpinnerTabType;
  activeTab: SpinnerTabType;
  onClick: () => void;
}) => {
  const isActive = props.tab === props.activeTab;
  return (
    <ListItemButton sx={{ my: 2 }} onClick={props.onClick}>
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle2" fontWeight={isActive ? 600 : 400}>
          {props.tab}
        </Typography>
        {/* {isActive ? } */}
        <ChevronRightIcon sx={{ opacity: isActive ? 1 : 0 }} />
      </Stack>
    </ListItemButton>
  );
};

const LuckyDrawer: React.FC<Props> = (props: Props) => {
  const [activeTab, setActiveTab] = useState<SpinnerTabType>(
    SpinnerTabType.Daily
  );
  const { loading, myBox } = useMyBox();
  const { loading: xpLoading, myXp } = useMyXp();
  // getBoxInfoById
  return (
    <Box>
      <Typography
        mb="30px"
        textAlign="center"
        variant="h2"
        display={{ xs: "none", md: "block" }}
      >
        Spinner
      </Typography>
      {loading || xpLoading ? (
        <Center py={3}>
          <CircularProgress />
        </Center>
      ) : myBox === null || myXp == null ? (
        <Center py={3}>
          <Typography>fetch data error</Typography>
        </Center>
      ) : (
        <Stack direction={{ xs: "column", md: "row" }} spacing="20px">
          <Box flex={1}>
            <Box sx={{ bgcolor: "#1D1839", borderRadius: "10px", px: 2 }}>
              <List>
                <TabItem
                  tab={SpinnerTabType.Daily}
                  activeTab={activeTab}
                  onClick={() => setActiveTab(SpinnerTabType.Daily)}
                />
                <Divider sx={{ borderColor: "#272142" }} />
                <TabItem
                  tab={SpinnerTabType.Progress}
                  activeTab={activeTab}
                  onClick={() => setActiveTab(SpinnerTabType.Progress)}
                />
              </List>
            </Box>
          </Box>
          <Box flex={3}>
            <Stack direction={{ xs: "column", md: "row" }} spacing="20px">
              <Box flex={1} sx={{ minWidth: "calc(50% - 10px)" }}>
                <Stack
                  direction={{ xs: "column-reverse", md: "column" }}
                  spacing={"20px"}
                  sx={{
                    bgcolor: { xs: "transparent", md: "#221C3F" },
                    borderRadius: "10px",
                    p: 3,
                  }}
                >
                  <Spinner boxId={myBox.vip_daily.box_id} myXp={myXp} />
                  <SinnerLevel />
                </Stack>
              </Box>
              <Box
                flex={1}
                sx={{ maxWidth: { xs: "100%", md: "calc(50% - 10px)" } }}
              >
                <SpinnerRewards boxId={myBox.vip_daily.box_id} />
              </Box>
            </Stack>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default LuckyDrawer;
