import Center from '@/components/center';
import useBoolean from '@/hooks/useBoolean';
import services from '@/services';
import { ILog } from '@/types/user';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import DatePicker from '@/components/DatePicker/DatePicker';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useLang from '@/hooks/useLang';
import LogsList from './LogList';

interface Props {}

const LogCard = ({ data }: { data: ILog }) => {
  return (
    <Box
      sx={{
        border: '1px solid #35314B',
        borderRadius: '10px',
        p: 2,
      }}
    >
      <Typography variant="body1" color="text.disabled" fontWeight={700}>
        <span style={{ color: '#ffffff', fontWeight: 700 }}>
          R$ {(+data?.money || 0).toFixed(2)}
        </span>{' '}
        {data.infos}
      </Typography>
      <Typography variant="body2" color="text.secondary" mt={1.5}>
        {dayjs(+(data?.add_time || 0) * 1000).format('YYYY-MM-DD HH:mm:ss')}
      </Typography>
    </Box>
  );
};

const TabLog: React.FC<Props> = (props: Props) => {
  const [logs, setLogs] = useState<ILog[]>([]);
  const [loading, setLoading] = useBoolean(true);
  const [total, setTotal] = useState<number>(0);
  const [start, setStart] = useState<string>(
    dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
  );
  const [end, setEnd] = useState<string>(
    dayjs().add(1, 'day').format('YYYY-MM-DD'),
  );
  const { __ } = useLang();

  useEffect(() => {
    if (!start || !end) {
      return;
    }

    const startDate = dayjs(start);
    const endDate = dayjs(end);

    if (startDate.isAfter(endDate)) {
      SnackbarUtils.error(__('结束时间不能小于开始时间'));
      return;
    }

    fetchLogs({
      page: 1,
      pagesize: 200,
      start_time: `${start} 00:00:00`,
      end_time: `${end} 00:00:00`,
    });
  }, [start, end]);

  const fetchLogs = async (params: any) => {
    const res = await services.auth.getLogs(params);
    setLogs(res?.data || []);
    setTotal(res?.total || 0);
    setLoading.off();
  };

  if (loading) {
    return (
      <Center py={10}>
        <CircularProgress />
      </Center>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          border: '1px solid #323D4D',
          borderRadius: { xs: '5px', md: '8px' },
          py: 0,
          px: 2,
          mb: 2,
          width: { xs: '100%', md: '280px' },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ height: '40px' }}
        >
          <Stack
            textAlign="center"
            flex="auto"
            direction="row"
            alignItems="center"
          >
            <DatePicker
              date={start}
              onChange={(v) => setStart(v)}
              maxDate={dayjs(end)}
            />
            <Typography variant="body2">-</Typography>
            <DatePicker
              date={end}
              onChange={(v) => setEnd(v)}
              minDate={dayjs(start)}
            />
          </Stack>
          <CalendarMonthIcon sx={{ fontSize: 16 }} />
        </Stack>
      </Box>
      <LogsList total={total} loading={loading} logs={logs} />
    </Box>
  );
};

export default TabLog;
