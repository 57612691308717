import ICONTG from '@/assets/icons/icon_tg.svg';
import useLang from '@/hooks/useLang';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';

// 个人中心下载引导
export const TGGuide = () => {
  const { __ } = useLang();

  const downloadTG = async () => {
    window.open('https://t.me/BrBabyOficial', '_blank');
  };

  return (
    <Box
      mt={2.5}
      sx={{
        bgcolor: 'background.paper',
        borderRadius: '10px',
        p: { xs: '12px 16px', md: 3 },
        mt: 2.5,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <img src={ICONTG} alt="" style={{ width: '32px', height: '32px' }} />
        <Typography
          variant="body1"
          color={'text.primary'}
          sx={{ mx: '8px', width: '50%' }}
        >
          {__('Join the telegram group, there will be surprises every day')}
        </Typography>
        <LoadingButton
          size="small"
          variant="contained"
          sx={{
            bgcolor: 'primary.contrastText',
            height: '32px',
            borderRadius: '8px',
            px: '12px',
            color: 'primary.main',
            textTransform: 'none',
            pointerEvents: 'all',
          }}
          onClick={downloadTG}
        >
          {__('Join in')}
        </LoadingButton>
      </Stack>
    </Box>
  );
};
