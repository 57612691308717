import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import services from '@/services';

import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import { isOpenTransferState } from '@/stores/auth';
import { ColorfulBg } from '@/styles';
import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

interface Props {}

/**
 * 提现弹窗内容
 *
 * @param {Props} props
 * @return {*}
 */
const TransferDrawerConten = (props: Props) => {
  const { __ } = useLang();
  const [openWithdraw, setOpenWithdraw] = useRecoilState(isOpenTransferState);

  const [amount, setAmount] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { getUserInfo, getWallet } = useAuth();

  const handleAmount = (money: string) => {
    const moneyT = money.replace(/[^\d.]/g, '');
    if (money.length > amount.length) {
      var regex = /^\d+(\.\d{1,2})?$/;
      if (regex.test(moneyT)) {
        setAmount(moneyT);
      }
    } else {
      setAmount(moneyT);
    }
  };

  const handleSubmit = async () => {
    if (loading) {
      return;
    }
    if (!parseFloat(amount) || parseFloat(amount) <= 0) {
      return;
    }

    setLoading(true);

    const isSuccess = await services.wallet.transferAgent({
      amt: parseFloat(amount),
    });

    if (isSuccess) {
      getWallet();
      getUserInfo();
      setOpenWithdraw(false);
      setAmount('');
      SnackbarUtils.success(__('Successfully'));
    }

    setLoading(false);
  };

  return (
    <Box pb={'8px'}>
      <Box px={2.5} pt={2.5} pb={4}>
        <Typography variant="body2" color={'text.secondary'}>
          {__('Transfer tip')}
        </Typography>

        <TextField
          sx={styles.input}
          InputProps={{
            style: {
              fontSize: '24px',
              height: '84px',
              backgroundColor: '#00000000',
            },
          }}
          id="outlined-helperText"
          value={amount}
          label={__('Amount')}
          onChange={(e) => handleAmount(e.target.value)}
          placeholder="0.00"
          variant="filled"
          type="number"
          inputProps={{
            inputMode: 'decimal',
            pattern: '[0-9]*[.]?[0-9]*',
          }}
        />

        <LoadingButton
          variant="contained"
          fullWidth
          sx={{
            background: ColorfulBg,
            boxShadow: 'none !important',
            borderRadius: '8px',
            mt: '16px',
          }}
          loading={loading}
          onClick={handleSubmit}
        >
          {__('Save')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

const styles = {
  input: {
    height: '84px',
    width: '100%',
    marginTop: '16px',
    borderRadius: '8px',
    bgcolor: 'card.main',
    border: '1px solid',
    borderColor: 'divider',
  },
};
export default TransferDrawerConten;
